
import { NavLink } from 'react-router-dom';
import { useSelector,  useDispatch } from 'react-redux';

import HamburgerBtn from './HamburgerBtn';

import  './Header.css';


const Navigation = () => {
    const dispatch = useDispatch();
    const navState = useSelector(state => state.navState);
    let style =''
    let bkdStyle = ''
    if (navState){
        style = 'open'
        bkdStyle = 'backdrop'

    }
    else {
        style = 'navigation hidden'
        bkdStyle = 'hidden'
    }
    const bkdHandler = () => {
   
        if(navState === true) {
          dispatch({type: 'close'});
        }
        else {
          dispatch({type: 'open'});  
        }
    }
  


    return (
        <div className='header'>
            <div className='header-container '>
                <div className='navigation-wrapper'>
                    <NavLink to='./' className='logo-link'>
                        <div className='logo'></div>
                    </NavLink>
                    <div className={style}>
                        <NavLink to='./careers'>CARRERS</NavLink>
                        <NavLink to='./technology'>TECHNOLOGY</NavLink>
                        <NavLink to='./our_people'>OUR PEOPLE</NavLink>
                        <NavLink to='./news'>NEWS</NavLink>
                    </div>
                    <HamburgerBtn></HamburgerBtn>
                    <div className={bkdStyle} onClick={bkdHandler}></div>
                </div>
            </div>
            
        </div>
        
    )
};

export default Navigation;