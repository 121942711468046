import Button from '../../UI/Button';

import  './MissionBlock.css';


const MissionBlock = () => {

    return <div className='mission-block'>
        <div className='mission-container'>
                <div className='title title-center'>What is Mear?</div>
                <div className='text'>Our mission is to give everyone creative space and show them other worlds. We believe that everyone deserves to build a better reality, a better place to live.</div>
                <Button href='' name='Read more'></Button> 
            </div> 
        
    </div>

}

export default MissionBlock;