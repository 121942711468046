import  './Button.css';

const Button = (props) => {
    return <div className='button'>
        <a href={props.href} className='button-text'>{props.name}</a>
    </div>
};

export default Button;

