
import  './VideoBlock.css';

import playBtn from '../../Assets/PlayBtn.svg';
import videoImg from '../../Assets/Rectangle.svg'
const VideoBlock = () => {

    return <div className='container'>
        <div className='title'>How does platform work?</div>
        <div className='video-wrapper'>
            <img src={videoImg} alt='video' className='video-img'></img>
            <a href='/' >
                <img src={playBtn} alt='playBtn' className='playBtn'></img>
            </a>
        </div>
    </div>

}

export default VideoBlock;