import  './FirstBlock.css';

import Button from '../../UI/Button';

import imgLayer from '../../Assets/Layer.svg'
const FirstBlock = () => {
    return <div className='first-block'>
        <div className='img-wrapper'>
             <img src={imgLayer} alt='layer' className='img-layer'></img>
             
        </div>
        <div className='content-container'>
            <div className='title'>Rethink your reality</div>
            <div className='btn-wrapper'>
                <Button href='' name='Start creating'></Button>
            </div>
        </div>
    </div>

}

export default FirstBlock;
