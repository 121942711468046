import Button from '../../UI/Button';

import  './TimerBlock.css';

const TimerBlock = () => {
    return <div className='container'>
        <div className='timer-block'>
            <div className='title title-center'>Ico ands in</div>
            <div className='timer'>
                <div className='time-container'>
                    <div className='time-frame center'>
                        <div className='time-box center'>
                            <p className='time'>21</p>
                        </div>
                    </div>
                    <div className='time-title'>Day</div>
                </div>
                <div className='time-container'>
                    <div className='time-frame center'>
                        <div className='time-box center'>
                            <p className='time'>27</p>
                        </div>
                    </div>
                    <div className='time-title'>Hours</div>
                </div>
                <div className='time-container'>
                    <div className='time-frame center'>
                        <div className='time-box center'>
                            <p className='time'>57</p>
                        </div>
                    </div>
                    <div className='time-title'>Minuts</div>
                </div>
                <div className='time-container'>
                    <div className='time-frame center'>
                        <div className='time-box center'>
                            <p className='time'>15</p>
                        </div>
                    </div>
                    <div className='time-title'>Secs</div>
                </div>
               
            </div>
            <div className='progres-bar-container'>
               <div className='progres-bar'>
                <div className='fill-bar'>
                    <div className='progres-btn'></div>
                </div>
            </div> 
            </div>
            
            <div className='bar-discription'>231 ETH of 600 ETH goal</div>
            <Button href='' name='Buy tokens'></Button>
        </div>
    </div>

}

export default TimerBlock;